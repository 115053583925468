import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import {
  ContainerCard,
  MagazineTableItem,
  StatusLabel,
  DropdownListingHeader,
} from "../../components";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { CreateMagazine } from "../popups/CreateMagazine";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import moment from "moment";
import { debounce } from "lodash";
import NoDataCard from "../../components/cards/NoDataCard";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
}));

const filterOptions = [
  { id: 0, label: "All" },
  { id: 1, label: "Draft" },
  { id: 2, label: "Published" },
];

export function Magazines(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [magazine, setMagazine] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPages] = useState(0);

  const { showToast } = useToast();
  const { logout } = useAuth();
  const [openAddMagazine, setOpenMagazine] = useState(false);

  const [selFilter, setSelFilter] = useState(filterOptions[0]);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getMagazines = () => {
    setIsLoading(true);
    api
      .get(urls.magazines, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          filter_key: selFilter.id === 0 ? "" : selFilter.label.toLowerCase(),
          search_key: searchInput.trim(),
        },
      })
      .then((res) => {
        setMagazine(res.data.data.magazines);
        setTotalPages(res.data.data.pagination.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getMagazines();
  }, [page, rowsPerPage, selFilter, searchInput]);

  useEffect(() => {
    if (location.state && location.state.filter) {
      let filter = filterOptions.find(
        (x) => x.label.toLowerCase() === location.state.filter.toLowerCase()
      );
      setSelFilter(filter);
      navigate("/magazines", { replace: true });
    }
  }, []);

  const delayedSearch = useCallback(
    debounce((text) => {
      setSearchInput(text);
    }, 500),
    []
  );

  const handleOnSearch = (event) => {
    delayedSearch(event.target.value);
    setPage(0);
  };

  const handleOnclickMagazine = () => {
    setOpenMagazine(true);
  };
  const handleCloseMagazine = () => {
    setOpenMagazine(false);
  };

  const handleSubmitMagazine = () => {
    handleCloseMagazine();
    getMagazines();
  };

  const handleMagazineClick = (id) => {
    navigate(`/magazines/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setSelFilter(event.target.value);
    setPage(0);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <DropdownListingHeader
          title={totalPage > 0 ? `Magazines (${totalPage})` : "Magazines"}
          btnTitle="Create New Magazine"
          placeholder="Search Magazine"
          onClickPrimary={handleOnclickMagazine}
          filterOptions={filterOptions}
          selFilter={selFilter}
          onFilterChange={handleFilterChange}
          onSearchChange={handleOnSearch}
        />
      </Grid>
      {isLoading && (
        <Grid item>
          <LoaderCard />
        </Grid>
      )}
      {!isLoading && (
        <Grid item>
          {magazine.length === 0 && !isLoading && (
            <NoDataCard title={"No Data Found"} />
          )}
          {magazine.length > 0 && (
            <ContainerCard>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header}>Magazine</TableCell>
                      <TableCell className={classes.header}>
                        Magazine Category
                      </TableCell>
                      <TableCell className={classes.header}>
                        No. of Articles
                      </TableCell>

                      <TableCell className={classes.header}>Status</TableCell>
                      <TableCell className={classes.header} align="right">
                        Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {magazine?.map((post, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{ cursor: "pointer" }}
                          hover
                          onClick={() => {
                            handleMagazineClick(post.id);
                          }}
                        >
                          <TableCell>
                            {
                              <MagazineTableItem
                                cover={post.cover_image}
                                book={`${post.book_number} - ${post.year_number}`}
                                name={post.issue_name}
                              />
                            }
                          </TableCell>
                          <TableCell>{post.product_set_name}</TableCell>
                          <TableCell>{post.articles_count}</TableCell>

                          {post.released === true ? (
                            <>
                              <TableCell className={classes.statusItem}>
                                <StatusLabel type="ready">
                                  Published
                                </StatusLabel>
                              </TableCell>
                              <TableCell align="right">
                                {moment(post.release_date).format("DD/MM/YYYY")}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>
                                <StatusLabel type="draft">Draft</StatusLabel>{" "}
                              </TableCell>
                              <TableCell align="right">
                                Modified on{" "}
                                {moment(post.updated_at).format("DD/MM/YYYY")}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={totalPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ContainerCard>
          )}
        </Grid>
      )}
      {openAddMagazine && (
        <CreateMagazine
          open={openAddMagazine}
          onClose={handleCloseMagazine}
          onSubmit={handleSubmitMagazine}
        />
      )}
    </Grid>
  );
}
