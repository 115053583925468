import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import {
  ListingHeader,
  ContainerCard,
  StatusLabel,
  AuthorTableItem,
  TableMenuButton,
} from "../../components";
import { makeStyles } from "@mui/styles";
import { AddAuthor } from "../popups/AddAuthor";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import { debounce } from "lodash";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import NoDataCard from "../../components/cards/NoDataCard";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
}));

const menuOptions1 = [
  { id: 1, label: "Edit" },
  { id: 2, label: "Delete" },
];

const menuOptions2 = [{ id: 1, label: "Edit" }];

export function Authors() {
  const classes = useStyles();
  const [openAddAuth, setOpenAddAuth] = useState(false);
  const [confirmDelAuth, setConfirmDelAuth] = useState(false);
  const { showToast } = useToast();
  const { logout } = useAuth();
  const [authors, setAuthors] = useState([]);
  const [selAuthor, setSelAuthor] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPages] = useState(0);

  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseAddAuth = () => {
    setOpenAddAuth(false);
    setSelAuthor(null);
  };

  const handleCloseEditAuth = () => {
    setConfirmDelAuth(false);
    setSelAuthor(null);
  };

  const handleOnClickPrimary = () => {
    setOpenAddAuth(true);
  };

  const getAuthor = () => {
    setIsLoading(true);
    api
      .get(urls.authors, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          filter_key: "",
          search_key: searchInput.trim(),
        },
      })
      .then((res) => {
        setAuthors(res.data.data.authors);
        setTotalPages(res.data.data.pagination.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getAuthor();
  }, [page, rowsPerPage, searchInput]);

  const delayedSearch = useCallback(
    debounce((text) => {
      setSearchInput(text);
    }, 500),
    []
  );

  const handleOnSearch = (event) => {
    delayedSearch(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getAuthor();
  };

  const handleReloadAuthor = () => {
    handleCloseAddAuth();
    getAuthor();
  };

  const handleMenuClick = (menu, item) => {
    if (menu.id === 1) {
      setOpenAddAuth(true);
      setSelAuthor(item);
    } else {
      setConfirmDelAuth(true);
      setSelAuthor(item);
    }
  };

  const onConfirmDeletion = () => {
    setConfirmDelAuth(false);
    api
      .delete(urls.deleteAuthor + selAuthor.id)
      .then((res) => {
        showToast("Author deleted successfully", "success");
        getAuthor();
        setSelAuthor(null);
      })
      .catch((err) => {
        setSelAuthor(null);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ListingHeader
            title={totalPage > 0 ? `Authors (${totalPage})` : "Authors"}
            btnTitle="Add New Author"
            placeholder="Search Author"
            onClickPrimary={handleOnClickPrimary}
            onSearchChange={handleOnSearch}
          />
        </Grid>
        {isLoading && (
          <Grid item>
            <LoaderCard />
          </Grid>
        )}
        {!isLoading && (
          <Grid item>
            {authors.length === 0 && !isLoading && (
              <NoDataCard title={"No Data Found"} />
            )}
            {authors.length > 0 && (
              <ContainerCard>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.header}>Author</TableCell>
                        <TableCell className={classes.header} align="center">
                          Articles
                        </TableCell>
                        {/* <TableCell className={classes.header}>Status</TableCell> */}
                        <TableCell width={100} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {authors?.map((item, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>
                              {
                                <AuthorTableItem
                                  authname={item.author_name}
                                  authimg={item.author_image}
                                />
                              }
                            </TableCell>
                            <TableCell align="center">
                              {item.articles_count}
                            </TableCell>
                            {/* <TableCell>
                          <StatusLabel type="free">Active</StatusLabel>
                        </TableCell> */}
                            <TableCell>
                              <TableMenuButton
                                options={
                                  item.articles_count > 1
                                    ? menuOptions2
                                    : menuOptions1
                                }
                                onOptionClick={(menu) =>
                                  handleMenuClick(menu, item)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  component="div"
                  count={totalPage}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </ContainerCard>
            )}
          </Grid>
        )}
      </Grid>
      {openAddAuth && (
        <AddAuthor
          open={openAddAuth}
          onClose={handleCloseAddAuth}
          onSubmit={handleReloadAuthor}
          isEdit={Boolean(selAuthor)}
          selAuthor={selAuthor}
        />
      )}
      {confirmDelAuth && (
        <ConfirmationDialog
          open={confirmDelAuth}
          onClose={handleCloseEditAuth}
          message="Are you sure you want to delete this Author?"
          onSubmit={onConfirmDeletion}
        />
      )}
    </Fragment>
  );
}
