import { useState, useRef } from "react";
import { Stack, Box, CircularProgress } from "@mui/material";
import { NormalTextField, UploadButton } from "..";
import PropTypes from "prop-types";
import { api, urls } from "../../services";
import { useToast } from "../../contexts";

export function FileUploader(props) {
  const { label, disabled, onChangeURL, url, fileType, error } = props;

  const imageFileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState(url);
  const [hasFile, setHasFile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { showToast } = useToast();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      // setFileURL(URL.createObjectURL(file));
      setHasFile(true);

      const formData = new FormData();
      formData.append("file", file);
      setIsUploading(true);
      api
        .post(urls.mediaUpload, formData, {
          // headers: { "Content-Type": "audio/form-data" },
        })
        .then((res) => {
          setFileURL(res.data.file_url);
          onChangeURL(res.data.file_url);
          setIsUploading(false);
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
          setIsUploading(false);
        });
    }
  };

  const handleUploadClick = () => {
    imageFileRef.current.click();
  };

  const handleDeleteClick = () => {
    setHasFile(false);
    setFile(null);
    setFileURL(null);
  };

  const getContentType = (v) => {
    switch (v) {
      case "image":
        return "image/*";
      case "audio":
        return "audio/*";
      case "video":
        return "video/*";
      default:
        return "image/*";
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <NormalTextField
        label={label}
        disabled={disabled}
        value={`${fileURL && fileURL.split("/").pop()}`}
        error={error}
      />
      <Box sx={{ width: 220 }}>
        <input
          id="file"
          type="file"
          ref={imageFileRef}
          style={{ display: "none" }}
          accept={getContentType(fileType)}
          onChange={handleFileChange}
        />
        {isUploading ? (
          <CircularProgress />
        ) : (
          <UploadButton onClick={handleUploadClick} height={55}>
            {hasFile ? "Change File" : "Upload File"}
          </UploadButton>
        )}
      </Box>
    </Stack>
  );
}

FileUploader.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeURL: PropTypes.func,
  url: PropTypes.string,
  fileType: PropTypes.string,
  error: PropTypes.bool,
};
